import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import refinersFire from "../../images/refiners-fire.jpg";

const MensBibleStudyPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-2xl text-left mb-4">
            Men's Bible Study
          </h1>
          <h2 className="text-white text-5xl font-serif text-left">
            Men sharpening men by the Word of God
          </h2>
        </div>
        <div className="p-8 md:p-16 grid grid-flow-row grid-cols-1 md:grid-cols-2 items-center gap-8 md:gap-16">
          <div>
            <div className=" text-cyan-900 text-2xl font-light">
              Breakfast. Coffee. Fellowship. Bible Study.
            </div>
            <div className="text-red-500 text-lg font-semibold">
              Notice: Men's Bible Study has been put on pause for the summer. Check back later!
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">When: </span>Every
              Thursday at 7am.
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">Where: </span>Wellspring
              Bible Church -{" "}
              <a
                className="text-blue-400"
                href="https://maps.app.goo.gl/VskRN7sz92ycgCZg7"
                target="_blank"
                rel="noreferrer"
              >
                304 North Rockhill Ave, Alliance, OH 44601
              </a>
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">What: </span>Join the
              men for an engaging Bible study, fellowship, coffee and breakfast.
              Our current study is in Exodus. Contact Pastor Rich for more
              details.
            </div>
          </div>
          <div>
            <img
              src={refinersFire}
              className="object-cover h-[36rem] w-full rounded-2xl shadow-xl"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default MensBibleStudyPage;
